<style lang="less" scoped>
	img{
	  width: 100%;
	  height: 100%;
	}
.taocan_tabs {
  width: 100%;
  height: 63px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  line-height: 63px;
  border-bottom: 4px solid #0090f0;
}
.tc_body {
  padding: 30px;
  box-sizing: border-box;
  .tc_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 200px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f8f8f8;
    margin-bottom: 30px;
    .tc_left {
      display: flex;
      img {
        width: 246px;
        height: 150px;
        margin-right: 20px;
      }
      > div {
        .tit {
          font-size: 18px;
        }
        .btm {
          font-size: 14px;
          color: #999;
          line-height: 2;
          margin-top: 60px;
        }
      }
    }
    .tc_rt {
      color: #f13232;
      font-size: 22px;
      div:nth-child(2) {
        width: 120px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        font-size: 16px;
        border: 1px solid #f13232;
        border-radius: 18px;
        margin-top: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>
<template>
  <div>
    <div class="taocan_tabs">超值套餐</div>
    <div class="tc_body">
      <div class="tc_item" v-for="(item,index) in taocanVal" :key="index">
        <div class="tc_left">
          <img :src="item.alias_litpic" alt="" />
          <div>
            <div class="tit">{{item.alias_name}}</div>
            <div class="btm">
              <div>课时数量：{{item.count}}</div>
              <div>学习人数：{{item.alias_purchase_num}}</div>
            </div>
          </div>
        </div>
        <div class="tc_rt">
          <div>原价:￥{{item.alias_price}}</div>
          <div @click="goTo(item.culum_id)">查看详情</div>
        </div>
      </div>
    </div>
  </div>
</template> 
<script>
export default {
  props: ["taocanVal"],
  data(){
    return{
      imgUrl:"http://v2.hnzye.cn/"
    }
  },
  methods:{
    goTo(id){
      this.$emit('changDetail',id)
    }
  }
};
</script>